import React, { Component } from 'react';
import {
  FileSolidIcon,
  Greyscale,
  PowerIcon,
  SettingSolidIcon,
  TicketSolidIcon,
  UserIcon,
} from 'glints-aries';
import Link from 'next/link';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ROUTES } from 'src/common/routes';
import { getIsExpertClassOrdersEnabled } from 'src/modules/ExpertClassOrder/Selectors';
import { settingPageLinkMessage } from 'src/modules/MainContainer/Navbar/messages';
import { logout } from 'src/modules/Session/Actions';
import { getUser } from 'src/selectors/user';

import {
  Dropdown,
  DropdownWrapper,
  NameHolder,
  ProfilePicture,
  UserMenuContainer,
  UserSettingSection,
} from './UserMenuComponents.sc';

class UserMenu extends Component {
  static propTypes = {
    className: PropTypes.string,
    me: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      profilePic: PropTypes.string,
      id: PropTypes.any,
    }),
    lastFetched: PropTypes.string,
    isExpertClassOrdersEnabled: PropTypes.bool,
    logout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    me: null,
  };

  renderDropDown(itemElement) {
    const {
      me: { firstName, lastName },
      isExpertClassOrdersEnabled,
      logout,
    } = this.props;

    return (
      <Dropdown
        aria-label="User menu"
        showHoverLine={true}
        dropDownPlacement="right"
        label={`${firstName} ${lastName}`}
        itemElement={itemElement}
        key={`${firstName} ${lastName}`}
      >
        <Dropdown.Item
          key="profile"
          onClick={() => Router.push(`/${ROUTES.profile}`)}
        >
          <DropdownWrapper>
            <UserIcon color={Greyscale.black} />
            <FormattedMessage
              id="my.profile"
              defaultMessage="My Profile"
              tagName="span"
            />
          </DropdownWrapper>
        </Dropdown.Item>

        <Dropdown.Item
          key="my-applications"
          onClick={() => Router.push(`/${ROUTES.applications}`)}
        >
          <DropdownWrapper>
            <FileSolidIcon color={Greyscale.black} />
            <FormattedMessage
              id="my.applications"
              defaultMessage="My Applications"
              tagName="span"
            />
          </DropdownWrapper>
        </Dropdown.Item>

        {isExpertClassOrdersEnabled && (
          <Dropdown.Item
            key="my-tickets"
            onClick={() => Router.push(`/${ROUTES.expertClassOrders}`)}
          >
            <DropdownWrapper>
              <TicketSolidIcon color={Greyscale.black} />
              <FormattedMessage
                id="user-menu.expert-class-ticket"
                defaultMessage="My Tickets"
              />
            </DropdownWrapper>
          </Dropdown.Item>
        )}
        <Dropdown.Item
          key="setting"
          onClick={() => Router.push(`/${ROUTES.settings}`)}
        >
          <DropdownWrapper>
            <SettingSolidIcon color={Greyscale.black} />
            <FormattedMessage {...settingPageLinkMessage} />
          </DropdownWrapper>
        </Dropdown.Item>
        <Dropdown.Item key={'log-out'} onClick={logout}>
          <DropdownWrapper>
            <PowerIcon color={Greyscale.black} />
            <FormattedMessage
              id="user.menu.sign.out"
              defaultMessage="Sign Out"
              tagName="span"
            />
          </DropdownWrapper>
        </Dropdown.Item>
      </Dropdown>
    );
  }

  renderUserSettingSection = () => {
    const {
      me: { firstName, lastName },
    } = this.props;
    return (
      <UserSettingSection>
        <Choose>
          <When condition={firstName || lastName}>
            {this.renderDropDown(
              <NameHolder>
                <If condition={firstName}>{firstName}</If>
                <If condition={!firstName}>{lastName}</If>
              </NameHolder>
            )}
          </When>
          <Otherwise>
            {this.renderDropDown(
              <NameHolder>
                <FormattedMessage
                  id="user.new"
                  defaultMessage="User"
                  tagName="span"
                />
              </NameHolder>
            )}
          </Otherwise>
        </Choose>
      </UserSettingSection>
    );
  };

  render() {
    return (
      <UserMenuContainer>
        <Link href="/profile">
          <a>
            <ProfilePicture
              profilePic={this.props.me.profilePic}
              userID={this.props.me.id}
              alt="Profile Picture"
              sizes="40px"
            />
          </a>
        </Link>
        {this.renderUserSettingSection()}
      </UserMenuContainer>
    );
  }
}

UserMenu = connect(
  state => ({
    me: getUser(state),
    lastFetched: state.user.me.lastFetched,
    isExpertClassOrdersEnabled: getIsExpertClassOrdersEnabled(state),
  }),
  {
    logout,
  }
)(UserMenu);

export default UserMenu;
