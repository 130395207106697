import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getRouterQueryProduct } from 'src/modules/SignUp/helper';

import { Button } from './DesktopMenuComponents.sc';

const DesktopSignupButton = ({ label }) => {
  const router = useRouter();
  const handleClick = () => {
    const query = getRouterQueryProduct(router);
    router.push({
      pathname: '/signup',
      query,
    });
  };
  return <Button onClick={handleClick}>{label}</Button>;
};

DesktopSignupButton.propTypes = {
  label: PropTypes.node.isRequired,
};

DesktopSignupButton.defaultProps = {
  label: (
    <FormattedMessage
      id="menu.button.signup"
      defaultMessage="Sign Up"
      tagName="span"
    />
  ),
};

export default DesktopSignupButton;
