import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CountryCodes } from 'src/common/enums';
import { useCountry } from 'src/common/hooks';
import { GoogleTagManager } from 'src/components/GoogleTagManager';
import { NewFeatureBadge } from 'src/components/NewFeatureBadge';
import DownloadAppFeatureChatModal from 'src/modules/CandidateMobileApp/Modals/DownloadAppFeaturingChatModal';

import { downloadAppMenuItemProps } from '../MenuItems';
import {
  CustomNavLink,
  MenuItem,
  NewFeatureBadgeContainer,
} from './DesktopMenuComponents.sc';

const DesktopDownloadMobileAppMenuVN = () => {
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false);
  const country = useCountry();

  if (country !== CountryCodes.VN) return null;

  const handleModalOnClose = () => setShowDownloadAppModal(false);
  const handleLinkOnClick: React.MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.preventDefault();
    setShowDownloadAppModal(true);
  };

  return (
    <MenuItem>
      <GoogleTagManager tag="nav-btn-download-app">
        <CustomNavLink href="#" onClick={handleLinkOnClick}>
          <NewFeatureBadgeContainer>
            <NewFeatureBadge />
          </NewFeatureBadgeContainer>
          <FormattedMessage {...downloadAppMenuItemProps.message} />
        </CustomNavLink>
      </GoogleTagManager>

      {showDownloadAppModal && (
        <DownloadAppFeatureChatModal onClose={handleModalOnClose} />
      )}
    </MenuItem>
  );
};

export default DesktopDownloadMobileAppMenuVN;
