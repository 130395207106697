import React from 'react';
import { Flex, SecondaryColor } from 'glints-aries';
import { Modal } from 'glints-aries/lib/@next/Modal';
import { Typography as AriesTypography } from 'glints-aries/lib/@next/Typography';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

import { MobileAppPlatform } from 'src/common/enums';
import { getAppAsset } from 'src/components/Button/DownloadMobileAppButton';

const getChatScreenshotAsset = (language: string) => {
  const locale = language === 'id' ? 'id' : 'en';
  return `images/mobile/glints-mobile-app-${locale}.webp`;
};

const messages = defineMessages({
  header: {
    id: 'text-get-glints-app',
    defaultMessage: 'Get Glints App',
  },
});

const Styled = {
  Modal: styled(Modal)`
    max-width: 720px;
    .modal-content {
      padding: 0 20px 0 0;
    }
  `,
  ModalContainer: styled(Flex)`
    gap: 0;
  `,
  TextContainer: styled(Flex)`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    padding-top: 20px;
  `,
  Typography: styled(AriesTypography)`
    text-align: center;
  `,
  TypographyTitle: styled(AriesTypography)`
    text-align: center;
    margin-bottom: 8px;
  `,
  TypographySubTitle: styled(AriesTypography)`
    text-align: center;
    margin-bottom: 40px;
  `,
  TypographyCtaText: styled(AriesTypography)`
    text-align: center;
    margin-bottom: 20px;
  `,
  DownloadContainer: styled.div`
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    align-content: center;
  `,
  QRCode: styled.div`
    grid-row: 1/3;
    margin-right: 12px;
    padding: 12px 12px 8px 12px;
    border: 1px solid #13d6f6;
    border-radius: 4px;
  `,
  StyledLink: styled.a`
    margin-bottom: -12px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
};

type DownloadAppFeatureChatModalIDProps = {
  onClose?: () => void;
};

const DownloadAppFeatureChatModalID = ({
  onClose,
}: DownloadAppFeatureChatModalIDProps) => {
  const intl = useIntl();
  const header = intl.formatMessage(messages.header);

  const screenshotAsset = getChatScreenshotAsset(intl.locale);
  const androidAppAsset = getAppAsset(MobileAppPlatform.ANDROID, intl.locale);
  const iOSAppAsset = getAppAsset(MobileAppPlatform.IOS, intl.locale);

  return (
    <Styled.Modal isOpen={true} header={header} onClose={onClose}>
      <Styled.ModalContainer>
        <img
          src={screenshotAsset}
          alt="Glints Mobile App"
          style={{ maxWidth: '320px' }}
        />

        <Styled.TextContainer>
          <Styled.TypographyTitle variant="headline4">
            <FormattedMessage
              id="text-get-real-time-update"
              defaultMessage="Get real-time updates"
            />
          </Styled.TypographyTitle>
          <Styled.TypographySubTitle
            variant="body2"
            color={SecondaryColor.actionblue}
          >
            <FormattedMessage
              id="text-never-miss-a-new-job-alert"
              defaultMessage="Never miss a new job alert.{br}Never miss a new HRD chat message."
              values={{
                br: <br />,
              }}
            />
          </Styled.TypographySubTitle>
          <Styled.TypographyCtaText variant="caption">
            <FormattedMessage
              id="text-scan-the-qr-code-or-download-the-glints-app-from"
              defaultMessage="Scan the QR code or download the Glints App from"
            />
          </Styled.TypographyCtaText>
          <Styled.DownloadContainer>
            <Styled.QRCode>
              <QRCode
                value="https://dynamic-link.glints.com/page/web2appqr"
                size={120}
              />
            </Styled.QRCode>
            <Styled.StyledLink
              href="https://bit.ly/4bk4j1u"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={androidAppAsset.src}
                alt={androidAppAsset.alt}
                width="180px"
              />
            </Styled.StyledLink>
            <Styled.StyledLink
              href="https://apple.co/44DZ9Lv"
              rel="noreferrer"
              target="_blank"
            >
              <img src={iOSAppAsset.src} alt={iOSAppAsset.alt} width="180px" />
            </Styled.StyledLink>
          </Styled.DownloadContainer>
        </Styled.TextContainer>
      </Styled.ModalContainer>
    </Styled.Modal>
  );
};

export default DownloadAppFeatureChatModalID;
