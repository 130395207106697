import React, { Fragment } from 'react';
import { Dropdown } from 'glints-aries';
import PropTypes from 'prop-types';
import { defineMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import { getCountry } from 'src/modules/ClientConfig/Selectors';

import DesktopDownloadMobileAppMenuVN from './DesktopDownloadMobileAppMenuVN';
import { MenuDropdown, MenuItem } from './DesktopMenuComponents.sc';

const DesktopLeftMenu = props => {
  const { renderMenuItem, authenticated, menuItems } = props;

  const intl = useIntl();
  const country = useSelector(getCountry);

  const countItems = () => {
    if (!authenticated) {
      return menuItems.filter(item => !item.requiresAuth);
    }
    return menuItems;
  };

  const filterMenuItems = countItems();

  return (
    <Fragment>
      {filterMenuItems.map((data, i) => {
        if (Array.isArray(data)) {
          return (
            <MenuDropdown
              key={data[0].label ?? i}
              label={intl.formatMessage(
                defineMessage({
                  id: 'hiring-solutions',
                  defaultMessage: 'Hiring solutions',
                })
              )}
            >
              {data.map(item => {
                return (
                  <Dropdown.Item key={data.label}>
                    {renderMenuItem(item, authenticated)}
                  </Dropdown.Item>
                );
              })}
            </MenuDropdown>
          );
        }

        return (
          <MenuItem key={data.label}>
            {renderMenuItem(data, authenticated)}
          </MenuItem>
        );
      })}
      {country === CountryCodes.VN && <DesktopDownloadMobileAppMenuVN />}
    </Fragment>
  );
};

DesktopLeftMenu.propTypes = {
  renderMenuItem: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default DesktopLeftMenu;
