import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openLoginSignUpModal } from 'src/actions/app';

import { Button } from './MenuDesktopComponents/DesktopMenuComponents.sc';

const LoginSignupButtonFn = ({ label }) => {
  const dispatch = useDispatch();
  const enhanceOnCLick = () => {
    dispatch(openLoginSignUpModal());
  };
  return <Button onClick={enhanceOnCLick}>{label}</Button>;
};

LoginSignupButtonFn.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['login', 'signup']).isRequired,
};

export const LoginSignupButton = LoginSignupButtonFn;
