import React from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { openLoginSignUpModal } from 'src/actions/app';
import { ROUTES } from 'src/common/routes';

import { LoginSignupButton } from '../LoginSignupButton';
import { useShouldUseLoginSignupModal } from '../useShouldUseLoginSignupModal';
import { Button } from './DesktopMenuComponents.sc';

const DesktopLoginButton = () => {
  const router = useRouter();
  const shouldUseLoginSignupModal = useShouldUseLoginSignupModal();
  const dispatch = useDispatch();
  const renderLabel = () => (
    <FormattedMessage
      id="menu.button.login"
      defaultMessage="Login"
      tagName="span"
    />
  );

  const handleOnClick = () => {
    if (router.pathname !== `/${ROUTES.login}`) {
      dispatch(openLoginSignUpModal());
    }
  };

  return (
    <Choose>
      <When condition={shouldUseLoginSignupModal}>
        <LoginSignupButton label={renderLabel()} type="login" />
      </When>
      <Otherwise>
        <Button onClick={handleOnClick}>{renderLabel()}</Button>
      </Otherwise>
    </Choose>
  );
};

export default DesktopLoginButton;
