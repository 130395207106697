import React from 'react';
import { ArrowRoundForwardIcon } from 'glints-aries';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import { useEmployersURL } from 'src/common/hooks';
import { getCountryWithSGFallback } from 'src/modules/ClientConfig/Selectors';
import { employerSiteLinkMessage } from 'src/modules/MainContainer/Navbar/messages';

import { EmployersButton } from './DesktopMenuComponents.sc';

const EmployersButtonCTA = () => {
  const employersBaseURL = new URL(useEmployersURL());
  employersBaseURL.searchParams.set('utm_source', 'marketplace');
  const country = useSelector(getCountryWithSGFallback);

  return (
    <EmployersButton
      small={true}
      block={true}
      endIcon={<ArrowRoundForwardIcon />}
      variant={country === CountryCodes.ID ? 'ghost' : 'solid-blue'}
      tag="a"
      // To be removed when glints-aries has TS support for tag properties.
      // @ts-ignore
      href={employersBaseURL.href}
      target="_blank"
    >
      <FormattedMessage {...employerSiteLinkMessage} />
    </EmployersButton>
  );
};

export default EmployersButtonCTA;
