import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { getClassName } from 'src/common/ComponentHelpers';
import { EXPERT_CLASS_PATH_REGEXPS } from 'src/common/routes';
import { NewFeatureBadge } from 'src/components/NewFeatureBadge';

import { NavbarLogo } from '../NavbarLogo';
import DesktopLeftMenu from './DesktopLeftMenu';
import {
  Anchor,
  Container,
  CustomNavLink,
  NewFeatureBadgeContainer,
} from './DesktopMenuComponents.sc';
import DesktopRightMenu from './DesktopRightMenu';

const DesktopMenu: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const router = useRouter();
  const { menuItems, authenticated, className } = props;
  const { left: leftMenuItems, right: rightMenuItems } = menuItems;
  const showExpertRecruitmentLink = EXPERT_CLASS_PATH_REGEXPS.some((regexp) =>
    regexp.test(router.pathname)
  );

  const renderMenuItem = (item: $TSFixMe, authenticated: boolean) => {
    if (!authenticated && item.requiresAuth) {
      return null;
    }

    const isActive = item.match ? item.match.test(router.pathname) : false;
    const ariaLabel = `${item.label} page`;

    return (
      <Choose>
        <When condition={item.external}>
          <Anchor
            key={item.label}
            href={item.to}
            className={getClassName(item.className)}
            aria-label={ariaLabel}
            target="_blank"
          >
            {item.new && (
              <NewFeatureBadgeContainer>
                <NewFeatureBadge />
              </NewFeatureBadgeContainer>
            )}
            <FormattedMessage {...item.message} />
          </Anchor>
        </When>
        <Otherwise>
          <Link
            key={item.label}
            href={item.to}
            as={item.as || item.to}
            passHref={true}
            prefetch={false}
          >
            <CustomNavLink
              className={getClassName(item.className)}
              // @ts-ignore TODO: remove ts-ignore when the component is migrated to TS.
              active={isActive}
              aria-label={ariaLabel}
            >
              {item.new && (
                <NewFeatureBadgeContainer>
                  <NewFeatureBadge />
                </NewFeatureBadgeContainer>
              )}
              <FormattedMessage {...item.message} />
            </CustomNavLink>
          </Link>
        </Otherwise>
      </Choose>
    );
  };

  return (
    <Container className={className}>
      <NavbarLogo />

      <DesktopLeftMenu
        menuItems={leftMenuItems}
        renderMenuItem={renderMenuItem}
        authenticated={authenticated}
      />

      {/** TODO: remove ts-ignore when the component is migrated to TS.
        // @ts-ignore */}
      <DesktopRightMenu
        menuItems={rightMenuItems}
        renderMenuItem={renderMenuItem}
        authenticated={authenticated}
        showExpertRecruitmentLink={showExpertRecruitmentLink}
        hideEmployersLink={showExpertRecruitmentLink}
      />
    </Container>
  );
};

DesktopMenu.defaultProps = {
  menuItems: {
    left: [],
    right: [],
  },
};

interface Props {
  authenticated: boolean;
  className?: string;
  menuItems?: {
    left: any[];
    right: any[];
  };
}

export default DesktopMenu;
