import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import LanguageSwitcher from 'src/components/GlintsLanguageSwitcher';
import {
  getCountry,
  getIsNotificationEnabled,
} from 'src/modules/ClientConfig/Selectors';
import FakeChatButtonMenu from 'src/modules/MainContainer/Navbar/MenuDesktopComponents/FakeChatButtonMenu';
import { getAvailableLanguages } from 'src/selectors/locale';

import { ExpertPlatformCTA } from '../ExpertPlaformCTA/ExpertPlatformCTA';
import UserMenu from '../UserMenu';
import DesktopDownloadMobileAppMenuID from './DesktopDownloadMobileAppMenuID';
import DesktopLoginButton from './DesktopLoginButton';
import { RightMenuContainer, UserMenuItem } from './DesktopMenuComponents.sc';
import DesktopSignupButton from './DesktopSignupButton';
import EmployersButtonCTA from './EmployersButtonCTA';

const NotificationButton = dynamic(() =>
  import(
    /* webpackChunkName: "NotificationButton" */ 'src/modules/Notification/Components/NotificationButton'
  )
);
const DesktopRightMenu = ({
  availableLanguages,
  menuItems,
  renderMenuItem,
  hideEmployersLink,
  showExpertRecruitmentLink,
  hideSignup,
  hideLogin,
  authenticated,
  isNotificationEnabled,
}) => {
  const marginRight = authenticated && '20px';
  const country = useSelector(getCountry);
  const isFakeChatButtonMenuEnabled = [
    CountryCodes.VN,
    CountryCodes.ID,
  ].includes(country);

  if (!country) return null;

  return (
    <RightMenuContainer>
      {country === CountryCodes.ID && <DesktopDownloadMobileAppMenuID />}
      {showExpertRecruitmentLink && (
        <UserMenuItem marginRight={marginRight}>
          <ExpertPlatformCTA />
        </UserMenuItem>
      )}
      <If condition={availableLanguages.length > 1}>
        <UserMenuItem marginRight={marginRight}>
          <LanguageSwitcher
            showHoverLine={true}
            languages={availableLanguages}
          />
        </UserMenuItem>
      </If>

      {menuItems.map(item => renderMenuItem(item, authenticated))}
      <Choose>
        <When condition={authenticated}>
          <If condition={isNotificationEnabled && authenticated}>
            <UserMenuItem marginRight={marginRight}>
              <NotificationButton />
            </UserMenuItem>
          </If>
          <If condition={isFakeChatButtonMenuEnabled}>
            <FakeChatButtonMenu />
          </If>
          <UserMenuItem marginRight="0px">
            <UserMenu />
          </UserMenuItem>
        </When>
        <Otherwise>
          <If condition={!hideSignup}>
            <UserMenuItem>
              <DesktopSignupButton />
            </UserMenuItem>
          </If>

          <If condition={!hideLogin}>
            <UserMenuItem>
              <DesktopLoginButton />
            </UserMenuItem>
          </If>

          <If condition={!hideEmployersLink}>
            <EmployersButtonCTA />
          </If>
        </Otherwise>
      </Choose>
    </RightMenuContainer>
  );
};

DesktopRightMenu.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string),
  menuItems: PropTypes.array,
  renderMenuItem: PropTypes.func.isRequired,
  hideEmployersLink: PropTypes.bool,
  hideSignup: PropTypes.bool,
  hideLogin: PropTypes.bool,
  showExpertRecruitmentLink: PropTypes.bool,
  isNotificationEnabled: PropTypes.bool.isRequired,
};

DesktopRightMenu.defaultProps = {
  hideEmployersLink: false,
  hideSignUp: false,
  hideLogin: false,
  showExpertRecruitmentLink: false,
};

export default connect(state => ({
  availableLanguages: getAvailableLanguages(state),
  isNotificationEnabled: getIsNotificationEnabled(state),
}))(DesktopRightMenu);
