import React from 'react';
import { Box, Flex, Greyscale, SecondaryColor } from 'glints-aries';
import { Modal } from 'glints-aries/lib/@next/Modal';
import { Typography } from 'glints-aries/lib/@next/Typography';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

const messages = defineMessages({
  header: {
    id: 'text-download-app-modal-title',
    defaultMessage: 'Download App',
  },
});

const Styled = {
  Modal: styled(Modal)`
    max-width: 640px;
    .modal-content {
      padding-bottom: 0;
    }
  `,
  QRCodeBox: styled.div`
    padding: 12px;
    max-width: 144px;
    border: 2px solid ${Blue.S54};
    text-transform: uppercase;
    line-height: 0;
  `,
  QRCodeHelperText: styled(Typography)`
    text-align: center;
    margin-bottom: 10px;
  `,
};

type DownloadAppFeatureChatModalProps = {
  onClose?: () => void;
};

const DownloadAppFeatureChatModal = ({
  onClose,
}: DownloadAppFeatureChatModalProps) => {
  const intl = useIntl();
  const header = intl.formatMessage(messages.header);

  return (
    <Styled.Modal isOpen={true} header={header} onClose={onClose}>
      <Flex style={{ gap: '16px' }}>
        <img
          src="images/mobile/chat-feature.jpg"
          alt="Glints Mobile App"
          style={{ maxWidth: '260px' }}
        />

        <div>
          <Typography variant="headline3">
            <FormattedMessage
              id="text-download-app-title"
              defaultMessage="Chat with the Hiring Manger"
            />
          </Typography>
          <Box my={4}>
            <Typography variant="headline3" color={SecondaryColor.actionblue}>
              <FormattedMessage
                id="text-download-app-body"
                defaultMessage="Get job instantly"
              />
            </Typography>
          </Box>
          <Box py={12}>
            <Typography variant="body1" color={Greyscale.devilsgrey}>
              <FormattedMessage
                id="text-get-the-app-now"
                defaultMessage="Only available on Glints App, scan the QR to get the app now."
              />
            </Typography>
          </Box>
          <Styled.QRCodeBox>
            <Styled.QRCodeHelperText variant="overline">
              <FormattedMessage
                id="text-scan-the-qr"
                defaultMessage="Scan with your phone"
              />
            </Styled.QRCodeHelperText>
            <img src="images/mobile/mobile-app-download-qr-code.png" />
          </Styled.QRCodeBox>
        </div>
      </Flex>
    </Styled.Modal>
  );
};

export default DownloadAppFeatureChatModal;
