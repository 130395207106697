import React, { useState } from 'react';
import { Button as GlintsButton } from 'glints-aries';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { CountryCodes } from 'src/common/enums';
import { pageCategoryMapper } from 'src/common/helpers';
import { useCountry } from 'src/common/hooks';
import pushGTMEvent from 'src/common/utils/google-tag-manager';
import DownloadAppFeaturingChatModalID from 'src/modules/CandidateMobileApp/Modals/DownloadAppFeaturingChatModalID';

import { MenuItem } from './DesktopMenuComponents.sc';

export const Button = styled(GlintsButton)`
  button {
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const DesktopDownloadMobileAppMenuID = () => {
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false);
  const country = useCountry();
  const router = useRouter();
  const intl = useIntl();

  const pageCategory = pageCategoryMapper(router.pathname);

  if (country !== CountryCodes.ID) return null;

  const handleModalOnClose = () => setShowDownloadAppModal(false);
  const handleLinkOnClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    setShowDownloadAppModal(true);
    pushGTMEvent('glints/GoogleTagManager/DOWNLOAD_APP_BUTTON_CLICKED', {
      payload: {
        placement: 'header',
        buttonCta: intl.formatMessage({
          id: 'text-get-glints-app',
          defaultMessage: 'Get Glints App',
        }),
        pageCategory,
      },
    });
  };

  return (
    <MenuItem>
      <Button
        onClick={handleLinkOnClick}
        variant="solid-blue"
        small={true}
        block={true}
        className="id-nav-btn-download-app"
      >
        <FormattedMessage
          id="text-get-glints-app"
          defaultMessage="Get Glints App"
        />
      </Button>

      {showDownloadAppModal && (
        <DownloadAppFeaturingChatModalID onClose={handleModalOnClose} />
      )}
    </MenuItem>
  );
};

export default DesktopDownloadMobileAppMenuID;
